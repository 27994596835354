<template>
    <client-page>

        <sub-visual sh="해외사업" tabActive="지역개발" bg="/images/sub/visual/sv2.jpg" />

        <section class="section">
            <v-container>

                <business-item tit="식수지원" sc="열악한 환경에 노출되어 있는 빈곤국가 아이들의 생명을 살리기 위해 식수직원 및 위생교육 사업을 진행하고 있습니다." file="water">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            우물 지원 : 식수가 필요한 지역에 마을 · 학교 단위로 공동 우물 개발
                        </li>
                        <li class="dot-text">
                            정수시설 및 물탱크 지원 : 우물이 나지 않은 지역의 경우 현지 사정에 맞는 정수 시설 및 물탱크 지원
                        </li>
                        <li class="dot-text">
                            간이정수기 지원 : 정수가 제대로 되지 않는 수도가 있는 지역은 가정단위로 간이정수기 지원
                        </li>
                        <li class="dot-text">
                            위생교육 실시 : 아이들과 주민들이 수인성 질병으로부터 스스로 생명을 지킬 수 있도록 위생에 대한 인식 개선 교육 실시
                        </li>
                    </ul>
                </business-item> 

                <business-item tit="주거환경개선" sc="도움이 필요한 가정들을 발굴해, 안전하고 편안하게 살아갈 수 있도록 주거환경 개보수 지원사업을 진행하고 있습니다." file="house">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            주택개보수(해피하우스) : 주택 내외부(벽, 바닥 등) · 지붕 · 창문 등 도움이 필요한 거주공간 개보수 지원
                        </li>
                        <li class="dot-text">
                            시설 개선 : 낙후된 기숙사 · 화장실 · 주방 · 전기 · 수도 · 냉난방 시설 등 개보수 및 설치 지원
                        </li>
                    </ul>
                </business-item> 

                <business-item tit="직업교육 지원" sc="주민들이 기술을 익히고 배울 수 있는 기회를 확대하고, 자립역량 강화를 위한 교육 환경을 제공하고 있습니다." file="edu">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            직업기술 교육지원 : 지역주민들의 직업기술 훈련이 가능한 직업훈련센터 마련 및 환경 조성 <br>
                            현지 수요에 맞는 전문적인 직업기술 교육으로 경제적 자립 지원
                        </li>
                        <li class="dot-text">
                            소득증대 활동 지원 : 지역주민들이 안정적인 생계를 이어갈 수 있도록 소득창출 사업 지원 <br>
                            경제적 · 사회적 가치를 동시에 창출할 수 있도록 해 지역사회 발전에 기여
                        </li>
                    </ul>
                </business-item> 

                <business-item tit="에너지 사업 지원" sc="빛 없이 살고 있는 전세계 에너지 소외지역에 태양광 에너지를 지원해 아이들에게 밝은 미래를 선물해 주고자 합니다." file="energe">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            솔라등 보급 : 어둠 속 위험한 사고를 예방하고 안전한 생활을 보장받을 수 있도록 전기가 없는 지역에 ‘솔라등’ 보급 <br>
                            아동 및 청소년에게 야간 학습 환경도 제공 가능
                        </li>
                        <li class="dot-text">
                            태양광 패널 : 빈곤 국가 지역주민들에게 태양광 패널을 지원해 전력 공급 <br>
                            빛은 물론 전자기기(핸드폰, 라디오 등) 활용이 가능하도록 지원
                        </li>
                    </ul>
                </business-item> 

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from '@/components/client/sub/sub-visual.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisual,
        BusinessItem,
    },
};
</script>